<template>
  <div class="contact">
    <Header message="Contact" />
    <h3>General, Press Inquiries, Mail orders:</h3>
    <a href="mailto:mizar.production@gmail.com"
    >mizar.production[at]gmail.com</a
    >
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Header from "../components/Header.vue";

export default defineComponent({
  components: {
    Header,
  },
});
</script>

<style lang="scss" scoped>
a {
  color: black;
  font-weight: 300;
  font-size: 18px;
  transition: color 0.3s;

  &:hover {
    color: #1cb1b1;
  }
}

@media screen and (max-width: 350px) {
  a {
    font-size: 15px;
  }
}
</style>
